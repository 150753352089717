import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { compose } from 'recompose';

const styles = (theme) => ({
    root: {
        marginTop: '1%',
    },

    typography: {
        fontWeight: 600,
        color: '#4A6D96',
        fontSize: 16,
        margin:theme.spacing.unit*2
    }
})
const Component = ({ children, title, classes }) => {

    return (
        <Grid container className={classes.root}>
            <Grid item xs={10}>
                <Grid container alignItems="center" direction="row">
                    <Grid item xs={2}>
                        <Grid container justify="flex-start" alignItems="center"  >
                            <Typography className={classes.typography}>
                                {title}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={10}>
                        <Grid container direction="row">
                            {children}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const enhance = compose(withStyles(styles));
export default enhance(Component);