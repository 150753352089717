import axios from 'axios';
import { saveToken, loadToken } from '../utils/sessionStorage';
import qs from 'querystring'

export const SET_FAMILIES = 'SET_FAMILIES';
export const SET_ID_USER = 'SET_ID_USER';
export const TOGGLE_USER_UPDATED_STATUS = 'TOGGLE_USER_UPDATED_STATUS';
export const SET_USER_BY_ID = 'SET_USER_BY_ID';
export const SET_USERS = 'SET_USERS';
export const SET_ACCOUNTS_CONVENTIONS_COUNT = 'SET_ACCOUNTS_CONVENTIONS_COUNT'
export const SET_USER_ERRORS = 'SET_USER_ERRORS';


export const setFamilies = (data) => ({ type: SET_FAMILIES, data });
export const setIdUser = (id) => ({ type: SET_ID_USER, id })
export const toggleUserUpdatedStatus = (status) => ({ type: TOGGLE_USER_UPDATED_STATUS, status });
export const setUserById = (data) => ({ type: SET_USER_BY_ID, data });
export const setUsers = (data) => ({ type: SET_USERS, data });
export const setAccountsConventionsCount = (data) => ({ type: SET_ACCOUNTS_CONVENTIONS_COUNT, data })
export const setErrorsUser=(data)=>({type:SET_USER_ERRORS,data})


axios.interceptors.request.use(function (config) {
    if (!config.url.startsWith('/hia-oauth2')) {
        const token = loadToken().access_token;
        config.headers.Authorization = 'Bearer ' + token;
    }
    return config;
});

const createAxiosResponseInterceptor = () => {
    const params = {
        grant_type: 'refresh_token',
        refresh_token: loadToken().refresh_token
    }
    const interceptor = axios.interceptors.response.use(
        response => response,
        error => {
            // Reject promise if usual error
            if (error.response.status !== 401) {
                return Promise.reject(error);
            }

            /* 
             * When response code is 401, try to refresh the token.
             * Eject the interceptor so it doesn't loop in case
             * token refresh causes the 401 response
             */
            axios.interceptors.response.eject(interceptor);

            return axios.post('/hia-oauth2/oauth/token', qs.stringify(params), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Acces-Control-Allow-Origin': '*'
                },

            }).then(response => {
                // dispatch(setToken(response.data))
                saveToken(response.data)
                error.response.config.headers['Authorization'] = 'Bearer ' + response.data.access_token;
                return axios(error.response.config);
            }).catch(error => {
                // saveToken();
                this.router.push('/signIn');
                return Promise.reject(error);
            }).finally(createAxiosResponseInterceptor);
        }
    );
}
createAxiosResponseInterceptor();
export const getFamilies = (level) => (dispatch) => {


    axios.get(`/hia-espace-client/api/v1/convention-families/${level}`)
        .then((response) => {
            if (response.status === 200) {
                dispatch(setFamilies(response.data))
            }

        }).catch(function (error) {
           
        })
}


export const getUserById = (id) => (dispatch) => {

    axios.get(`/hia-espace-client/api/v1/accounts/${id}`)
        .then((response) => {
            if (response.status === 200) {
                dispatch(setUserById(response.data))
            }
        }).catch(function (error) {
            console.log('error')
        })
}


export const addUser = (payload) => (dispatch) => {
    axios.post("/hia-espace-client/api/v1/accounts", {


        "conventionFamilys": payload.family,
        "email": payload.email,
        "firstName": payload.firstname,
        "internalRef": payload.internalReference,
        "language": payload.language,
        "lastName": payload.lastname,
        "login": payload.login,
        "phone": payload.phoneNumber,
        "randomConvention": payload.random,
        "securityLevel": payload.securityLevel,
        "title": payload.title,
        "type": payload.type



    }, {

            headers: {
                'Content-Type': 'application/json',
                'Acces-Control-Allow-Origin': '*',
            },

        }).then((response) => {
            if (response.status === 200) {

                dispatch(setIdUser(response.data.id))
            }
        }).catch(function (error) {
            if(error.response.data){
                dispatch(setErrorsUser(error.response.data.errors[0].message))
            }
        })
}

export const UpdateUser = (payload) => (dispatch) => {

    axios.put("/hia-espace-client/api/v1/accounts",
        payload,
        {
            headers: {
                'Content-Type': 'application/json',
                'Acces-Control-Allow-Origin': '*'
            },

        }).then((response) => {
            if (response.status === 200) {

                dispatch(toggleUserUpdatedStatus('ok'))
            }
        }).catch(function (error) {
            if(error.response.data){
                dispatch(setErrorsUser(error.response.data.errors[0].message))
            }
        })
}
export const closeAccount = (payload) => (dispatch) => {

    axios.put(`/hia-espace-client/api/v1/accounts/close`, payload,
        {
            headers: {
                'Content-Type': 'application/json',
                'Acces-Control-Allow-Origin': '*'
            },

        }).then((response) => {
            if (response.status === 200) {

                dispatch(getUserById(payload.id))
            }
        }).catch(function (error) {
            console.log('ko')
        })
}

export const loadUsers = (page, size) => (dispatch) => {

    axios.get(`/hia-espace-client/api/v1/accounts?page=${page}&size=${size}`)
        .then((response) => {
            if (response.status === 200) {
                dispatch(setUsers(response.data))
            }
        }).catch(function (error) {
            console.log('errorLoading')
        })
}

export const searchUser = (term, startsWith, searchType, searchLevel, dateFrom, dateTo, withoutActivity,conventionStatus, accountStatus, sort, page, size) => (dispatch) => {
    axios.get(`/hia-espace-client/api/v1/accounts/search?query=${term}&startWithQuery=${startsWith}&typeAccounts=${searchType}&securityLevels=${searchLevel}&dateFrom=${dateFrom}&dateTo=${dateTo}&withoutActivity=${withoutActivity}&accountStatus=${accountStatus}&conventionStatus=${conventionStatus}&sort=lastName,${sort}&page=${page}&size=${size}`)
        .then((response) => {
            if (response.status === 200) {
                dispatch(setUsers(response.data))
                dispatch(getCounts(term, startsWith, searchType, searchLevel, dateFrom, dateTo, withoutActivity,conventionStatus, accountStatus))
            }
        }).catch(function (error) {
            console.log('errorLoading')
        })
}

export const getCounts = (term, startsWith, searchType, searchLevel, dateFrom, dateTo, withoutActivity,conventionStatus, accountStatus) => (dispatch) => {
    axios.get(`hia-espace-client/api/v1/accounts/stat?query=${term}&startWithQuery=${startsWith}&typeAccounts=${searchType}&securityLevels=${searchLevel}&dateFrom=${dateFrom}&dateTo=${dateTo}&withoutActivity=${withoutActivity}&accountStatus=${accountStatus}&conventionStatus=${conventionStatus}`).then((response) => {
        if (response.status === 200) {
            dispatch(setAccountsConventionsCount(response.data))
        }
    }).catch(function (error) {
        console.log('errorLoading')
    })
}
export const getUsersFiltredByStatus = (conventionStatus, accountStatus) => (dispatch) => {
    axios.get(`hia-espace-client/api/v1/accounts/search-by-status?accountStatus=${accountStatus}&conventionStatus=${conventionStatus}`).then((response) => {
        if (response.status === 200) {
            dispatch(setUsers(response.data))
        }
    }).catch(function (error) {
        console.log('errorLoading')
    })
}

export const openAccount = (payload) => (dispatch) => {

    axios.put(`/hia-espace-client/api/v1/accounts/open`, payload,
        {
            headers: {
                'Content-Type': 'application/json',
                'Acces-Control-Allow-Origin': '*'
            },

        }).then((response) => {
            if (response.status === 200) {

                dispatch(getUserById(payload.id))
            }
        }).catch(function (error) {
            console.log('ko')
        })
}
