import React from 'react';
import { withStateHandlers, lifecycle } from 'recompose';
import { Field, Form } from 'react-final-form';
import TextField from '../../forms/TextField';
import Grid from '@material-ui/core/Grid';
import FormContainer from '../../widgets/FormContainer';
import Select from '../../forms/Select';
import { civility, level, type, language } from '../../utils/select.js'
import { FormattedMessage } from 'react-intl';
import PhoneNumber from '../../forms/PhoneNumber';
import Switch from '../../forms/Switch';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core';
import { isPhoneNumber } from '../../forms/utils';
import { injectIntl } from 'react-intl';
import PersonAdd from '@material-ui/icons/PersonAdd';
import { compose } from 'ramda';
import { withRouter } from 'react-router-dom';
import Spacer from '../../widgets/Spacer';
import Title from '../../widgets/Title';
import SelectAll from '../../forms/SelectAll';
import FormErrors from '../../widgets/FormErrors';
import { formatFamilies } from '../../utils/models';
import SelectLevel from '../../forms/SelectLevel';
import { MANDATORY_FIELDS } from '../../utils/constantDefinition';

const styles = (theme) => ({
    root: {
        marginTop: '4%'
    },
    icon: {
        fontSize: 40,
        marginLeft: "2%",
        color: 'rgba(45,56,130,1)',
    },
    title: {
        fontWeight: 600,
        color: '#28558a',
        marginLeft: "1%"
    },

    button: {
        backgroundColor: '#fff',
        color: '#777777',
        fontSize: '14px',
        width: 150,
        border: '1px solid rgba(45,56,130,1)',
        textTransform: 'none',

    },
    addButton: {
        textTransform: 'none',
        backgroundColor: "#4bacc682",
        color: "#777777",
        fontSize: '14px',
        width: 150,
        '&:hover': {
            backgroundColor: '#fff',
        },
        border: '1px solid rgba(45,56,130,1)',

    },
    typography: {
        color: 'red',
        fontWeight: 600,
        fontSize: 14
    },
    header: {
        paddingBottom: theme.spacing.unit * 1,
        paddingTop: theme.spacing.unit * 5,
        paddingLeft: theme.spacing.unit * 1,

    },
    paper: {
        width: '90%',
        background: "none",
        display: "flex",
        border: '1px solid rgba(0, 0, 0, 0.23)',
        marginTop: '1.4%',
        height: 49,
    }


})

const Component = ({ classes, person, intl, families, actions, toggleCheckBox, selected, userErrors,clientLanguage }) => {
    const onSubmit = (values) => {
        if (!values.login || !values.email || !selected[0] || !values.phoneNumber) {
            actions.setUserErrors(MANDATORY_FIELDS)
        }
        else {
            actions.setUserErrors()
            const newValues = { ...values, family: selected ? formatFamilies(selected, families) : values.family };
            actions.addUser(newValues)
        }

    }
    const country = "FR";
    return (
        <Form
            onSubmit={onSubmit}
            initialValues={{ ...person, language: clientLanguage, securityLevel: 'LEVEL_1', type: "employee", random: true }}
            render={({ handleSubmit, pristine, form, invalid, values }) => (
                <form onSubmit={handleSubmit}>
                    <Grid container>
                        <Grid item xs={10}>
                            <Grid container className={classes.header}>
                                <Grid item xs={2}>
                                    <Grid container justify="flex-start" alignItems="flex-end"  >
                                        <PersonAdd className={classes.icon} />
                                        <Spacer size="MEDIUM" />
                                        <Title text={<FormattedMessage id="user" />} />
                                    </Grid>
                                </Grid>
                                <Grid item xs={10}>
                                    <Grid container>
                                        <Grid item xs={8}>
                                            <Grid container justify="flex-start" alignItems="flex-end">
                                                <Button disabled={pristine} onClick={() => { form.reset(); actions.setUserErrors() }} className={classes.button}  >
                                                    cancel
                                             </Button>
                                                <Spacer size="MEDIUM" />
                                                <Button type="submit" className={classes.addButton} >
                                                    add user
                                        </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid />
                            </Grid>
                        </Grid>
                        <FormErrors isError={userErrors} />
                        <FormContainer title={<FormattedMessage id="user.reference" />}>
                            <Grid item xs={4}>
                                <Grid container justify="flex-start" alignItems="flex-end">
                                    <Field
                                        component={Select}
                                        name="type"
                                        type="select"
                                        label={`${intl.formatMessage({ id: "user.type" })} *`}
                                        object={type}
                                        def="employee"

                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <Grid container justify="flex-start" alignItems="flex-end">
                                    <Field
                                        component={TextField}
                                        name="internalReference"
                                        type="text"
                                        label={<FormattedMessage id="user.internal.ref" />}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <Grid container justify="flex-start" alignItems="flex-end">
                                    <Field
                                        component={Select}
                                        name="language"
                                        type="select"
                                        label={<FormattedMessage id="user.language" />}
                                        object={language}
                                    />
                                </Grid>
                            </Grid>

                        </FormContainer>
                        <FormContainer title={<FormattedMessage id="user.name" />}>
                            <Grid item xs={4}>
                                <Grid container justify="flex-start" alignItems="flex-end">
                                    <Field
                                        component={Select}
                                        name="title"
                                        type="select"
                                        label={<FormattedMessage id="user.title" />}
                                        object={civility}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <Grid container justify="flex-start" alignItems="flex-end">
                                    <Field
                                        component={TextField}
                                        name="firstname"
                                        type="text"
                                        label={<FormattedMessage id="user.firstname" />}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <Grid container justify="flex-start" alignItems="flex-end">
                                    <Field
                                        component={TextField}
                                        name="lastname"
                                        type="text"
                                        label={<FormattedMessage id="user.lastname" />}
                                    />
                                </Grid>
                            </Grid>

                        </FormContainer>

                        <FormContainer title={<FormattedMessage id="user.contact" />}>
                            <Grid item xs={4}>
                                <Grid container justify="flex-start" alignItems="flex-end">
                                    <Field

                                        component={TextField}
                                        name="login"
                                        type="text"
                                        label={`${intl.formatMessage({ id: "user.login" })} *`}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <Grid container justify="flex-start" alignItems="flex-end">
                                    <Field

                                        component={TextField}
                                        name="email"
                                        type="email"
                                        label={`${intl.formatMessage({ id: "user.email" })} *`}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <Grid container justify="flex-start" alignItems="flex-end">

                                    <Field

                                        validate={isPhoneNumber("wrong format", country)}
                                        component={PhoneNumber}
                                        country={country.toUpperCase()}
                                        name="phoneNumber"
                                        type="text"
                                        label={`${intl.formatMessage({ id: "user.cell.phone" })} *`}
                                        flagSize="lg"
                                    />
                                </Grid>
                            </Grid>

                        </FormContainer>
                        <FormContainer title={<FormattedMessage id="user.convention" />}>
                            <Grid item xs={4}>
                                <Grid container justify="flex-start" alignItems="flex-end">
                                    <Field
                                        component={SelectLevel}
                                        name="securityLevel"
                                        type="select"
                                        label={`${intl.formatMessage({ id: "user.level" })} *`}
                                        object={level}
                                        actions={actions}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <Grid container justify="flex-start" alignItems="flex-end">
                                    <Field
                                        component={SelectAll}
                                        //disabled={true}
                                        toggleCheckBox={toggleCheckBox}
                                        selected={selected}
                                        name="family"
                                        label={`${intl.formatMessage({ id: "user.family" })} *`}
                                        type="select"
                                        elements={type}
                                        families={families}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <Grid container justify="flex-start" alignItems="flex-end">
                                    <Paper className={classes.paper}>
                                        <Field
                                            component={Switch}
                                            name="random"
                                            color="primary"
                                            type="checkbox"
                                            label="Random"
                                        />

                                    </Paper>
                                </Grid>
                            </Grid>

                        </FormContainer>
                    </Grid>
                </form>
            )}
        />
    )
}

const withCheckBox = withStateHandlers(() => ({ selected: [] }), {
    toggleCheckBox: () => value => ({ selected: value }),
});

const loadComponents = lifecycle({
    componentWillReceiveProps(nextProps) {
        const { families, toggleCheckBox, user, actions, history } = this.props
        if (nextProps.families !== undefined && nextProps.families !== families) {
            toggleCheckBox(nextProps.families.map(fam => fam.id))
        }
        if (nextProps.user !== user) {
            actions.getUser(nextProps.user)
            history.push(`/user/${nextProps.user}`)
        }


    }


},
);
export const enhance = compose(
    withStyles(styles),
    withRouter,
    withCheckBox,
    injectIntl,
    loadComponents);
export default enhance(Component);